import { Inject, Injectable } from '@angular/core';
import { TenantService } from '../tenant/tenant.service';
import { ProfileService } from '../profile/profile.service';
import { APP_BASE_HREF } from '@angular/common';
import { BasicInfoService } from '../basic-info/basic-info.service';
import { LocalStorageService } from '../localstorage/localstorage.service';
import { StoreService } from '../store/store.service';
import { RestaurantSettingsService } from '../restaurant/restaurant-settings.service';
import { MatDialog } from '@angular/material/dialog';
import { SelectStoreComponent } from '../../includes/select-store/select-store.component';

@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  private dialogOpened = false;

  constructor(
    private tenantService: TenantService,
    private profileService: ProfileService,
    @Inject(APP_BASE_HREF) private baseHref: string,
    private basicInfoService: BasicInfoService,
    private storeService: StoreService,
    private restaurantSettingsService: RestaurantSettingsService,
    private localStorageService: LocalStorageService,
    private dialog: MatDialog
  ) {}

  appInitializer() {
    this.initTenantName().then(() => {
      this.storeService.setupStore().then(() => {
        this.ensureStoreSelection();
      });
    });
    this.checkLogin();
  }

  ensureStoreSelection() {
    if (
      !this.dialogOpened &&
      !this.storeService.selectedStore.value &&
      (!this.storeService.storeList.value || this.storeService.storeList.value.length > 1)
    ) {
      this.dialogOpened = true;

      const enterAnimationDuration: string = '500ms';
      const exitAnimationDuration: string = '500ms';

      const dialogRef = this.dialog.open(SelectStoreComponent, {
        disableClose: true,
        enterAnimationDuration,
        exitAnimationDuration,
        panelClass:'dialog-dark'
      });

      dialogRef.afterClosed().subscribe(() => {
        this.dialogOpened = false;
        this.storeService.ensureStoreSelected().subscribe(() => {
          this.appInitialization();
        });
      });
    } else {
      this.appInitialization();
    }
  }


  appInitialization(): void {
    this.restaurantSettingsService.getTax();
    this.restaurantSettingsService.getTipsEnableConfig();
    this.restaurantSettingsService.getStripeProcessingFee();
  }

  checkLogin() {
    const token = this.localStorageService.getItem('token') ?? '';
    if (token) {
      this.profileService.loggedIn.next(true);
      this.profileService.getProfile();
    }
  }


  async initTenantName(): Promise<void> {
    const currentUrl = window.location.hostname;
    console.log('currentUrl => ', currentUrl);
    console.log('baseHref => ', this.baseHref);
    const baseUrl = this.baseHref.split('/');
    console.log('baseUrl[1] => ', baseUrl[1]);

    return new Promise<void>((resolve, reject) => {
      if (this.baseHref && this.baseHref !== '/') {
        this.tenantService.getTenantByDomain(baseUrl[1]).subscribe(
          (res) => {
            if (res) {
              this.tenantService.setTenantAndDomain(res, baseUrl[1]);
              this.basicInfoService.getBasicInfo(true);
              resolve();
            }
          },
          (err) => {
            reject();
          }
        );
      } else {
        this.tenantService.getTenantByUrl(currentUrl).subscribe(
          (res) => {
            if (res) {
              this.tenantService.setTenantName(res);
              this.basicInfoService.getBasicInfo(true);
              resolve();
            }
          },
          (err) => {
            reject();
          }
        );
      }
    });
  }
}
