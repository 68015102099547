import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpConfigService } from '../core/httpConfig/httpConfigService';
import { LocalStorageService } from './localstorage/localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class AppStoreLinkService {
  api: string;
  appStoreLinks = new BehaviorSubject<any | null>(null);

  constructor(
    private apiConfig: HttpConfigService,
    private localStorageService: LocalStorageService
  ) {
    this.api = this.apiConfig.getBaseUrl + 'app-store';
  }

  /**
   * Fetch app store links from API
   */
  getAppStoreLinksFromAPI(): Observable<any> {
    return this.apiConfig.get(this.api);
  }

  /**
   * Retrieve app store links, optionally forcing a fresh API call
   */
  public getAppStoreLinks(forceGet: boolean = false) {
    const cachedLinks = this.localStorageService.getItem('app-store-links');

    if (cachedLinks && !forceGet) {
      this.appStoreLinks.next(JSON.parse(cachedLinks));
    } else {
      this.getAppStoreLinksFromAPI().subscribe(
        (links) => {
          this.setAppStoreLinks(links);
        },
        (error) => {
          console.error('Error fetching app store links:', error);
        }
      );
    }
  }

  /**
   * Cache app store links in local storage and update BehaviorSubject
   */
  private setAppStoreLinks(links: any) {
    this.localStorageService.setItem('app-store-links', JSON.stringify(links));
    this.appStoreLinks.next(links);
  }

  /**
   * Clear app store links from local storage and reset BehaviorSubject
   */
  public clearAppStoreLinks() {
    this.appStoreLinks.next(null);
    this.localStorageService.removeItem('app-store-links');
  }
}
