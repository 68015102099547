import { Component, Inject } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { FooterComponent } from './includes/footer/footer.component';
import { HeaderComponent } from './includes/header/header.component';
import { AppInitializerService } from './appServices/core/app-initializer.service';
import { BasicInfoService } from './appServices/basic-info/basic-info.service';
import { MatDialog } from '@angular/material/dialog';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DesignUtilityServices } from './appServices/design-utility.service';
import { RestaurantSettingsService } from './appServices/restaurant/restaurant-settings.service';
import { AgeProofPromptComponent } from './includes/popups/age-proof-prompt/age-proof-prompt.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterLink, RouterOutlet, HeaderComponent, FooterComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']  // Corrected to 'styleUrls'
})
export class AppComponent {
  showConsentBanner = true;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private _appInit: AppInitializerService,
    private basicInfoService: BasicInfoService,
    private _du: DesignUtilityServices,
    private dialog: MatDialog  // Added MatDialog for the modal
  ) { }

  ngOnInit() {
    this._appInit.appInitializer();
    this.getBasicInfo();
  }

  scrollToTop(): void {
    // Check if 'window' is available before using it
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
  }

  /**
   * get basic info
   */
  getBasicInfo(): void {
    this.basicInfoService.basicInfo.subscribe(
      (res) => {
        if (res?.businessType === 'cannabis') {
          this.openAgeProofModel();
        }
      },
      (error) => {
        this._du.openCustomSnackBar('Failed to fetch basic info:'+error, 'Close', 'error');
        // Handle error scenario (e.g., show an error message to the user)
      }
    );
  }

  openAgeProofModel(): void {
    const enterAnimationDuration: string = '500ms';
    const exitAnimationDuration: string = '500ms';

    // Uncomment and update when the component is created
    this.dialog.open(AgeProofPromptComponent, { 
      disableClose: true,
      enterAnimationDuration,
      exitAnimationDuration,
      panelClass:'dialog-dark'
    });
  }

  hideConsentBanner() {
    this.showConsentBanner = false;
  }


}
