<header>
    <span class="menu-toggler dshow" [ngClass]="{active:menuToggler}"  (click)="onMenuToggle()"><i></i></span>

    <!-- <a routerLink="/" class="logo">
        <img [src]="basicInfo?.imageLink
        ? S3_URL+''+basicInfo?.bucketName+'/'+basicInfo?.imageLink
        :'images/avatar.svg'" alt="Logo">
    </a> -->

    <a class="logo" [routerLink]="['/']">
        @if (basicInfo && basicInfo.imageLink) {
            <img [src]="S3_URL + basicInfo.bucketName + '/' + basicInfo.imageLink" class="img" alt="Logo">
        } @else if (basicInfo && basicInfo.darkLogo) {
            <img [src]="S3_URL + basicInfo.bucketName + '/' + basicInfo.darkLogo" class="img" alt="Logo">
        } @else {
            <h4 class="logo pointer no-outline">{{ basicInfo?.restaurantName }}</h4>
        }
    </a>

    <button mat-icon-button (click)="openStoreModel()" class="dshow mr-auto">
        <mat-icon>storefront</mat-icon>
    </button>

    <span class="store dhide" (click)="openStoreModel()">
        <strong>Store</strong>
        <span class="selected-store color-secondary">
            {{ selectedStore ? selectedStore.storeName : 'Select Store' }}
        </span>
    </span>

    <button mat-button class="ml-auto dhide" [routerLink]="['reward']">Rewards</button>
    <button mat-button class="dhide" [routerLink]="['leaderboard']">Leaderboard</button>

    @if (user && cartCount > 0) {
        <button mat-button [routerLink]="['bag']" class="bag dhide">
            <div class="flex-item-center">
                @if (getCartTotalPrice() > 0) {
                    <span>
                        {{ getCartTotalPrice() | currency: basicInfo?.currencyCode }}
                    </span>
                }
                <mat-icon class="material-icons-outlined" [matBadge]="cartCount" matBadgePosition="after"
                    matBadgeColor="primary">shopping_bag</mat-icon>
            </div>
        </button>
        <button mat-icon-button [routerLink]="['bag']" class="bag dshow ml-auto">
            <mat-icon class="material-icons-outlined" [matBadge]="cartCount" matBadgePosition="after"
                matBadgeColor="primary">shopping_bag</mat-icon>
        </button>
    }

    @if (user) {
        <button mat-icon-button class="profile-btn ml-3" [matMenuTriggerFor]="menu">
            <figure class="profile-pic">
                <img src="images/avatar.svg" />
            </figure>
        </button>
    } @else {
        <button mat-icon-button class="profile-btn ml-3" (click)="openAuthModal()">
            <figure class="profile-pic">
                <img src="images/avatar.svg" />
            </figure>
        </button>
    }
</header>

<!-- Profile menu -->
<mat-menu #menu="matMenu" xPosition="before" class="menu">
    <button mat-menu-item [routerLink]="['profile']">
        <mat-icon>person</mat-icon>
        <span>Profile</span>
    </button>
    <button mat-menu-item (click)="logout()">
        <mat-icon>power_settings_new</mat-icon>
        <span>Log Out</span>
    </button>
</mat-menu>
<div class="overlay" [class.show]="menuToggler" (click)="onMenuToggle()"></div>
<div class="menu-panel" [class.open]="menuToggler">

    <a class="logo-menu" [routerLink]="['/']">
        @if (basicInfo && basicInfo.imageLink) {
            <img [src]="S3_URL + basicInfo.bucketName + '/' + basicInfo.imageLink" class="img" alt="Logo">
        } @else if (basicInfo && basicInfo.darkLogo) {
            <img [src]="S3_URL + basicInfo.bucketName + '/' + basicInfo.darkLogo" class="img" alt="Logo">
        } @else {
            <h4 class="logo pointer no-outline">{{ basicInfo?.restaurantName }}</h4>
        }
    </a>

    <div class="nav">
        <button mat-button [routerLink]="['reward']">Rewards</button>
        <button mat-button [routerLink]="['leaderboard']">Leaderboard</button>
    </div>
</div>
