import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { TenantInterceptor } from './appInterceptors/tenant.interceptor';
import { BasicAuthInterceptor } from './appInterceptors/basic-auth-interceptor.service';
import { provideNgxStripe } from 'ngx-stripe';
import { provideNativeDateAdapter } from '@angular/material/core';

// Domain Configs
import { getDomainName } from './core/domain-config';
import { APP_BASE_HREF } from '@angular/common';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }), 
    provideRouter(routes), 
    provideNgxStripe(),
    provideClientHydration(), 
    provideAnimationsAsync(),
    provideNativeDateAdapter(),
    provideHttpClient(withInterceptors([
      TenantInterceptor,
      BasicAuthInterceptor
    ])),
    { provide: APP_BASE_HREF, useFactory: getDomainName }
  ]
};
