<div class="wrapper">
  <app-header />
  <router-outlet (activate)="scrollToTop()"/>
  <app-footer />

  @if(false){
    <div class="consent-banner">
      <div>
        <!-- <span class="h-close"></span> -->
        <h2>Consent banner</h2>
        <p><span>
          This website uses cookies. By using this website, you agree to the use of cookies. For More Info Read our
          </span><a routerLink="privacy-policy">Policy</a>. </p>
          <a class="agreed-btn">Agree</a>
      </div>
    </div>
  }
</div>