import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { register } from 'swiper/element/bundle';
import { defineCustomElements } from '@teamhive/lottie-player/loader';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
register();

if (typeof window !== 'undefined') {
  // Only define custom elements when running in the browser
  defineCustomElements(window);
}

bootstrapApplication(AppComponent, {
  ...appConfig,
  providers: [
    ...appConfig.providers,
    provideHttpClient(withFetch()), // Enables fetch API for SSR
    { provide: PLATFORM_ID, useValue: 'browser' },
  ],
})
  .catch((err) => console.error(err));
