import { Routes } from '@angular/router';
import { authGuard } from './appGuards/auth.guard';

export const routes: Routes = [
  {
    path: 'home',
    loadComponent: () => import('./pages/home/home.component').then(m => m.HomeComponent)
  },
  {
    path: 'bag',
    loadComponent: () => import('./pages/bag/bag.component').then(m => m.BagComponent),
    canActivate:[authGuard]
  },
  {
    path: 'checkout',
    loadComponent: () => import('./pages/checkout/checkout.component').then(m => m.CheckoutComponent),
    canActivate:[authGuard]
  },
  {
    path: 'product/:id',
    children: [
      {
        path: '',
        loadComponent: () => import('./pages/product/product.component').then(m => m.ProductComponent)
      },
      {
        path: 'label',
        loadComponent: () => import('./pages/product/label/label.component').then(m => m.LabelComponent)
      }
    ]
  },
  {
    path: 'receipt/:id',
    loadComponent: () => import('./pages/receipt/receipt.component').then(m => m.ReceiptComponent)
  },
  {
    path: 'cms/:title',
    loadComponent: () => import('./pages/cms/cms.component').then(m => m.CmsComponent)
  },
  {
    path: '',
    pathMatch: 'full',
    loadComponent: () => import('./pages/home/home.component').then(m => m.HomeComponent)
  },
  {
    path: 'reward',
    loadComponent: () => import('./pages/reward/reward.component').then(m => m.RewardComponent)
  },
  {
    path: 'leaderboard',
    loadComponent: () => import('./pages/leaderboard/leaderboard.component').then(m => m.LeaderboardComponent)
  },
  {
    path: 'profile',
    loadComponent: () => import('./pages/profile/profile.component').then(m => m.ProfileComponent),
    canActivate:[authGuard]
  },
  {
    path: 'thanks',
    loadComponent: () => import('./pages/thanks/thanks.component').then(m => m.ThanksComponent)
  },
  {
    path: 'terms',
    loadComponent: () => import('./pages/terms/terms.component').then(m => m.TermsComponent)
  },
  {
    path: 'flyers/:flyerId/:mobileNumber',
    loadComponent: () => import('./pages/flyers/flyers.component').then(m => m.FlyersComponent)
  },
  {
    path: '',
    pathMatch: 'full',
    loadComponent: () =>
      import('./pages/home/home.component').then((m) => m.HomeComponent),
  }

];
