import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpConfigService } from '../core/httpConfig/httpConfigService';
import { LocalStorageService } from './localstorage/localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class SocialMediaLinkService {
  api: string;
  socialMediaLinks = new BehaviorSubject<any | null>(null);

  constructor(
    private apiConfig: HttpConfigService,
    private localStorageService: LocalStorageService
  ) {
    this.api = this.apiConfig.getBaseUrl + 'social-media-link';
  }

  /**
   * Fetch social media links from API
   */
  getSocialMediaLinksFromAPI(): Observable<any> {
    return this.apiConfig.get(this.api);
  }

  /**
   * Retrieve social media links, optionally forcing a fresh API call
   */
  public getSocialMediaLinks(forceGet: boolean = false) {
    const cachedLinks = this.localStorageService.getItem('social-media-links');

    if (cachedLinks && !forceGet) {
      this.socialMediaLinks.next(JSON.parse(cachedLinks));
    } else {
      this.getSocialMediaLinksFromAPI().subscribe(
        (links) => {
          this.setSocialMediaLinks(links);
        },
        (error) => {
          console.error('Error fetching social media links:', error);
        }
      );
    }
  }

  /**
   * Cache social media links in local storage and update BehaviorSubject
   */
  private setSocialMediaLinks(links: any) {
    this.localStorageService.setItem('social-media-links', JSON.stringify(links));
    this.socialMediaLinks.next(links);
  }

  /**
   * Clear social media links from local storage and reset BehaviorSubject
   */
  public clearSocialMediaLinks() {
    this.socialMediaLinks.next(null);
    this.localStorageService.removeItem('social-media-links');
  }
}
