import { Component, OnInit } from '@angular/core';
import { BasicInfoService } from '../../../appServices/basic-info/basic-info.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-age-proof-prompt',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule
  ],
  templateUrl: './age-proof-prompt.component.html',
  styleUrl: './age-proof-prompt.component.scss'
})
export class AgeProofPromptComponent implements OnInit {
  basicInfo: any;
  constructor(
    private basicInfoService: BasicInfoService) {
      this.getBasicInfo();
    }



  ngOnInit(): void {
  }
  /**
 * get basic info
 */
  getBasicInfo(): void {
    this.basicInfoService.basicInfo.subscribe(
      (res) => {
        if (res) {
          this.basicInfo = res;
        }
      }
    );
  }

  redirectToTastio() {
    window.location.href = 'https://www.tastio.com';
  }

}
