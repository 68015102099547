import { Component, Inject } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { FooterComponent } from './includes/footer/footer.component';
import { HeaderComponent } from './includes/header/header.component';
import { AppInitializerService } from './appServices/core/app-initializer.service';
import { BasicInfoService } from './appServices/basic-info/basic-info.service';
import { MatDialog } from '@angular/material/dialog';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Title } from '@angular/platform-browser'; // Import Title service
import { Renderer2 } from '@angular/core';
import { environment } from '../environments/environment';
import { AgeProofPromptComponent } from './includes/popups/age-proof-prompt/age-proof-prompt.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [AgeProofPromptComponent, RouterLink, RouterOutlet, HeaderComponent, FooterComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  showConsentBanner = true;
  S3_URL = environment.S3_URL;
  tenantReady: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private _appInit: AppInitializerService,
    private basicInfoService: BasicInfoService,
    private titleService: Title,
    private dialog: MatDialog,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this._appInit.appInitializer();
    this.getBasicInfo();
    setTimeout(() => {
      this.tenantReady = true;
    }, 1000);
  }

  
  

  scrollToTop(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
  }

  /**
   * Fetches basic info and dynamically sets the title and favicon.
   */
  getBasicInfo(): void {
    this.basicInfoService.basicInfo.subscribe(
      (res) => {
        if (res?.businessType === 'cannabis') {
          this.openAgeProofModel();
        }
        if (res?.restaurantName) {
          this.titleService.setTitle(res.restaurantName); // Dynamically set the title
        }
        if (res?.bucketName && (res.imageLink || res.darkLogo)) {
          const faviconUrl = `${this.S3_URL}${res.bucketName}/${
            res.imageLink || res.darkLogo
          }`;
          this.updateFavicon(faviconUrl);
        }
      },
      (error) => {
        console.log("Error: ", error);
      }
    );
  }

  /**
   * Updates the favicon dynamically using S3_URL and basicInfo values.
   * @param faviconUrl - The full URL of the favicon.
   */
  updateFavicon(faviconUrl: string): void {
    if (isPlatformBrowser(this.platformId)) {
      const link: HTMLLinkElement | null = document.querySelector(
        "link[rel='icon']"
      );
      if (link) {
        this.renderer.setAttribute(link, 'href', faviconUrl);
      } else {
        const newLink: HTMLLinkElement = this.renderer.createElement('link');
        this.renderer.setAttribute(newLink, 'rel', 'icon');
        this.renderer.setAttribute(newLink, 'href', faviconUrl);
        this.renderer.appendChild(document.head, newLink);
      }
    }
  }

  openAgeProofModel(): void {
    const enterAnimationDuration: string = '500ms';
    const exitAnimationDuration: string = '500ms';

    this.dialog.open(AgeProofPromptComponent, {
      disableClose: true,
      enterAnimationDuration,
      exitAnimationDuration,
      panelClass: 'dialog-dark',
    });
  }

  hideConsentBanner() {
    this.showConsentBanner = false;
  }
}
