import { Injectable } from '@angular/core';
import { HttpConfigService } from '../../core/httpConfig/httpConfigService';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../localstorage/localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  api:string;

  constructor(
    private apiConfig: HttpConfigService,
    private localStorageService: LocalStorageService
  ){
    this.api = this.apiConfig.getBaseUrl;
  }


  /*------------------------------------------------------------------
    Common Apis
  -------------------------------------------------------------------*/

  /**
   * Set tenant & domain
   * @param tenant 
   * @param domain 
   */
  setTenantAndDomain(tenant:string, domain:string){
    this.setTenantName(tenant);
    this.setDomain(domain);
  }


  /**
   * set domain
   * @param name 
   */
  setDomain(name: string) {
    this.localStorageService.setItem('domain', name);
  }

  /**
   * set tenant name
   * @param name 
   */
  setTenantName(name: string) {
    this.localStorageService.setItem('tenant', name);
  }

  /**
   * get domain name
   * @returns 
   */
  getTenantName(): string|null {
    return this.localStorageService.getItem('tenant');
  }

  clearTenantName() {
    this.localStorageService.removeItem('tenant');
  }


  
  /*------------------------------------------------------------------
    Apis
  -------------------------------------------------------------------*/
  /**
   * Get tenant by domain
   * @param domain 
   * @returns 
   */
  getTenantByDomain(domain: string): Observable<any> {
    return this.apiConfig.get(this.api + 'tenant/domain/' + domain);
  }
  

  /**
   * get tenant name by url
   * @param url 
   */
  getTenantByUrl(url: string): Observable<any> {
    return this.apiConfig.get(this.api + 'tenant?url=' + url);
  }

 


  

  

}
