<footer class="container">
  <div class="row1">
    <a [routerLink]="['/']" class="logo">
      @if (basicInfo && basicInfo.imageLink) {
        <img [src]="S3_URL + basicInfo.bucketName + '/' + basicInfo.imageLink" alt="Tastio logo">
      } @else if (basicInfo && basicInfo.darkLogo) {
        <img [src]="S3_URL + basicInfo.bucketName + '/' + basicInfo.darkLogo" alt="Tastio dark logo">
      } @else {
        <span class="logo-text">{{ basicInfo?.restaurantName || 'Tastio' }}</span>
      }
    </a>
    <ul class="flinks">
      @for (cms of cmsList; track $index) {
        <li>
          <a
            [routerLink]="['/','cms', cms.title]" target="_blank">
            {{ cms.title }}
          </a>
        </li>
      }
    </ul>

  </div>

  <div class="row2">
    <ul class="social-links">
      @if (socialMediaLinks && socialMediaLinks.length > 0) {
        @for (item of socialMediaLinks; track $index) {
          @if (item) {
            @if (item.name === 'facebook' && item.url) {
              <li>
                <a [href]="item.url" target="_blank" rel="noopener noreferrer">
                  <i class="fa fa-facebook"></i>
                </a>
              </li>
            }
            @if (item.name === 'instagram' && item.url) {
              <li>
                <a [href]="item.url" target="_blank" rel="noopener noreferrer">
                  <i class="fa fa-instagram"></i>
                </a>
              </li>
            }
            @if (item.name === 'twitter' && item.url) {
              <li>
                <a [href]="item.url" target="_blank" rel="noopener noreferrer">
                  <i class="fa fa-twitter"></i>
                </a>
              </li>
            }
          }
        }
      } @else {
        <!-- Placeholder if no social links available -->
        <li><span></span></li>
      }
    </ul>
    <span class="copyright">
      &copy; Copyright <a href="https://tastio.com" target="_blank"
      rel="noopener noreferrer" class="color-primary">Tastio</a>. All Rights Reserved
    </span>
  </div>
</footer>
