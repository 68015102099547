import { isPlatformBrowser } from '@angular/common';
import { HttpInterceptorFn } from '@angular/common/http';
import { inject, PLATFORM_ID } from '@angular/core';
import { LocalStorageService } from '../appServices/localstorage/localstorage.service';

export const TenantInterceptor: HttpInterceptorFn = (req, next) => {
  const platformId = inject(PLATFORM_ID);
  const localStorageService = inject(LocalStorageService);
  const tenantName = isPlatformBrowser(platformId) ? localStorageService.getItem('tenant') ?? '' : '';

  if (tenantName) {
    const cloned = req.clone({
      setHeaders: {
        'tenant-name': tenantName,
      },
    });
    return next(cloned);
  } else {
    return next(req);
  }
};
 