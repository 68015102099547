import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { DesignUtilityServices } from '../../appServices/design-utility.service';
import { ProfileService } from '../../appServices/profile/profile.service';
import { IStore } from '../../appInterfaces/IStore';
import { StoreService } from '../../appServices/store/store.service';
import { IProfile } from '../../appInterfaces/IProfile';
import { CartService } from '../../appServices/cart/cart.service';
import { CurrencyPipe, NgClass } from '@angular/common';
import { IBasicInfo } from '../../appInterfaces/IBasicInfo';
import { BasicInfoService } from '../../appServices/basic-info/basic-info.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CurrencyPipe,
    RouterLink,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatMenuModule,
    MatInputModule,
    MatBadgeModule,
    NgClass
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  S3_URL = environment.S3_URL;
  basicInfo!: IBasicInfo | null;
  loggedIn: boolean = false;
  selectedStore!: IStore | null;
  user!: IProfile | null;
  cartCount!: number;
  menuToggler:boolean = false;
  private cartSubscription: Subscription | undefined;

  constructor(
    private _du: DesignUtilityServices,
    private basicInfoService: BasicInfoService,
    private profileService: ProfileService,
    private cartService: CartService,
    private storeService: StoreService
  ) {}

  ngOnInit(): void {
    this.getBasicInfo();
    this.getLoggedInUser();
    this.subscribeSelectedStore();

    

    // this.getCartInfo(); // Initial cart load
  }

  getBasicInfo(): void {
    this.basicInfoService.basicInfo.subscribe(
      (res) => {
        this.basicInfo = res;
      }
    );
  }

  ngOnDestroy(): void {
    // Unsubscribe to avoid memory leaks
    if (this.cartSubscription) {
      this.cartSubscription.unsubscribe();
    }
  }

  getLoggedInUser(): void {
    this.profileService.profile.subscribe((res) => {
      this.user = res;
      if (this.user) {
        this.getCartInfo();

        // Subscribe to cart updates to refresh the cart when it changes
        this.cartSubscription = this.cartService.cartUpdated$.subscribe(() => {
          this.getCartInfo(); // Re-fetch cart info after a successful order
        });
      }
    });
  }

  getCartInfo(): void {
    this.cartService.getCart();
    this.cartService.cart.subscribe((res) => {
      if (res) {
        this.cartCount = res.length;
      } else {
        this.cartCount = 0; // Reset cart count if cart is empty
      }
    });
  }

  getCartTotalPrice(): number {
    return this.cartService.totalPriceOfCart();
  }

  subscribeSelectedStore() {
    this.storeService.selectedStore.subscribe((res) => {
      this.selectedStore = res;
    });
  }

  openAuthModal() {
    this._du.openAuthModal();
  }

  openStoreModel(){
    this._du.openStoreModel();
  }

  logout() {
    let data = {
      title: "Are you sure?",
      text: 'Do you want to logout?'
    }
    this._du.openPrompt(data).subscribe(
      (res) => {
        if (res) {
          this.profileService.logout();
        }
      }
    );
  }


  
  onMenuToggle(){
    // this.menuToggler = !this.menuToggler;
    // document.getElementsByTagName('html')[0].classList.toggle('no-scroll');

    if(this.menuToggler){ //menu open
      this.menuToggler = false;
      // document.getElementsByTagName('html')[0].classList.remove('hideScroll');
      // var topValue = $('#wrapper').css('top');
      // $('#wrapper').css('top', '');
      // $('.menu-wrapper').scrollTop(0);
      // setTimeout(function () {
      //   $(window).scrollTop(Math.abs(parseInt(topValue)));
      // }, 30);
    }
    else{//menu close
      this.menuToggler = true;
      // var scrollTop = $(window).scrollTop();
      // $('#wrapper').css('top', -scrollTop);
      // document.getElementsByTagName('html')[0].classList.add('hideScroll');
    }
  }


}
