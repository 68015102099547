import { a as patchEsm, b as bootstrapLazy } from './core-e4dd7c38.js';
const defineCustomElements = (win, options) => {
  return patchEsm().then(() => {
    bootstrapLazy([["lottie-player", [[1, "lottie-player", {
      "mode": [1],
      "autoplay": [4],
      "background": [513],
      "controls": [4],
      "count": [2],
      "direction": [2],
      "hover": [4],
      "loop": [516],
      "renderer": [1],
      "speed": [2],
      "src": [1],
      "currentState": [1, "current-state"],
      "seeker": [8],
      "intermission": [2],
      "play": [64],
      "pause": [64],
      "stop": [64],
      "seek": [64],
      "getLottie": [64],
      "setSpeed": [64],
      "setDirection": [64],
      "setLooping": [64],
      "togglePlay": [64],
      "toggleLooping": [64]
    }]]]], options);
  });
};
export { defineCustomElements };