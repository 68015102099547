import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { IBasicInfo } from '../../appInterfaces/IBasicInfo';
import { BasicInfoService } from '../../appServices/basic-info/basic-info.service';
import { environment } from '../../../environments/environment';
import { AppStoreLinkService } from '../../appServices/app-store-link.service';
import { SocialMediaLinkService } from '../../appServices/social-media-link.service';
import { NgClass } from '@angular/common';
import { CmsService } from '../../appServices/cms/cms.service';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    RouterLink
  ],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  basicInfo!: IBasicInfo | null;
  S3_URL = environment.S3_URL;
  storeLinks: any;
  socialMediaLinks: any;
  cmsList!: any[];

  constructor(
    private basicInfoService: BasicInfoService,
    private appStoreLinkService: AppStoreLinkService,
    private socialMediaLinkService: SocialMediaLinkService,
    private cmsService: CmsService
  ) {}

  ngOnInit(): void {
    this.getBasicInfo();
    this.getCMSList();
    this.getAppStoreLinks();
    this.getSocialMediaLinks();
  }

  /**
   * Fetch basic information
   */
  getBasicInfo(): void {
    this.basicInfoService.basicInfo.subscribe((res) => {
      this.basicInfo = res;
    });
  }

  /**
   * Fetch app store links and set them to storeLinks property
   */
  getAppStoreLinks(): void {
    this.appStoreLinkService.getAppStoreLinks();
    this.appStoreLinkService.appStoreLinks.subscribe((links) => {
      this.storeLinks = links;
      console.log('App Store Links:', this.storeLinks);
    });
  }

  /**
   * Fetch social media links and set them to socialMediaLinks property
   */
  getSocialMediaLinks(): void {
    this.socialMediaLinkService.getSocialMediaLinks();
    this.socialMediaLinkService.socialMediaLinks.subscribe((links) => {
      this.socialMediaLinks = links;
      console.log('Social Media Links:', this.socialMediaLinks);
    });
  }

  /**
     * get all cms list
     */
  getCMSList(): void {
    this.cmsService.getAllCMSPages().subscribe(
      (res) => {
        this.cmsList = res;
      }
    );
}
}
