import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { LocalStorageService } from '../appServices/localstorage/localstorage.service';

export const BasicAuthInterceptor: HttpInterceptorFn = (req, next) => {
  if (!req.url.includes('/tenant')) {
    const localStorageService = inject(LocalStorageService);
    
    const token = localStorageService.getItem('token') ?? '';
    if (token) {
      const cloned = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
      return next(cloned);
    }
  }
  return next(req);
};