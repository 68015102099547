import { DOCUMENT } from '@angular/common';
import { inject } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { PREDEFINED_URLS } from './TastioUrls';


// Function to get the document reference
function getDocumentReference() {
    return inject(DOCUMENT);
}

// Function to determine the base URL
export function getDomainName(): string {
    const document = getDocumentReference();
    const host = document.location.host;
    const pathname = document.location.pathname;
    const tastioUrls = PREDEFINED_URLS;

    if (tastioUrls.includes(host) && pathname !== "") {
      const domainName = "/" + pathname.split('/')[1];
      return domainName;
    }
    return '/'; // Default base href if conditions don't match
}